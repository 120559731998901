import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

let analytics;

if (import.meta.env.PROD) {
  const app = initializeApp({
    apiKey: 'AIzaSyCqWv8MFQUBrul47dT8hGa14tyhZ80CpBw',
    authDomain: 'awraq-336316.firebaseapp.com',
    projectId: 'awraq-336316',
    storageBucket: 'awraq-336316.appspot.com',
    messagingSenderId: '1040904898259',
    appId: '1:1040904898259:web:1d19a2410e837f65de7626',
    measurementId: 'G-GZK4L91JEJ',
  });

  analytics = getAnalytics(app);
}

export const trackScreenView = (screenName) => {
  if (import.meta.env.PROD) {
    logEvent(analytics, 'screen_view', {
      firebase_screen: screenName,
      firebase_screen_class: screenName,
    });
  }
};
