import './config/firebase.js';
import { createApp, h } from 'vue';
import VueLoadersBallBeat from 'vue-loaders/dist/loaders/ball-beat';
import AOS from 'aos';
import { theme } from '@/config/theme.js';
import { createInertiaApp, Link, router } from '@inertiajs/vue3';

import { can, route, toast, trans } from '@/utils/helpers.ts';
import { variantJS } from '@variantjs/vue';
import VueToast from 'vue-toast-notification';
import './css.js';
import { init as initSentry, replayIntegration } from '@sentry/vue';
import Vue3ColorPicker from 'vue3-colorpicker';
import 'vue3-colorpicker/style.css';
import { fetcher } from '@/utils/fetcher.js';
import { trackScreenView } from '@/config/firebase';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { Icon } from '@iconify/vue';

export const mobileAppBaseUrl = '/api/v1/mobile';

createInertiaApp({
  resolve: (name) =>
    resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  setup({ App, props, plugin }) {
    let app = createApp({ render: () => h(App, props) });

    if (import.meta.env.PROD) {
      initSentry({
        app: app,
        dsn: 'https://18cc4db1041246818432d4e1f2fbb2a6@o547686.ingest.sentry.io/5887404',
        tracesSampleRate: 0.5,
        integrations: [replayIntegration()],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }

    app
      .use(plugin)
      .use(VueLoadersBallBeat)
      .use(variantJS, theme)
      .use(VueToast)
      .use(Vue3ColorPicker)
      .use(VueQueryPlugin)
      .mixin({ methods: { route, can, fetcher, trans } })
      .component('inertia-link', Link)
      .component('iconify-icon', Icon)
      .mount('#app');
  },
  progress: {
    delay: 0,
    color: '#34d399',
    showSpinner: false,
  },
});

AOS.init({
  once: true,
  duration: 850,
});

router.on('success', ({ detail: { page } }) => {
  if (page.props.success) {
    toast(page.props.success);
  }
});

router.on('navigate', (event) => {
  trackScreenView(event.detail.page.url);
});
