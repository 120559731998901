export const dict = {
  'show-branch': 'استعراض شجرة',
  'edit-branch': 'تعديل شجرة',
  'manage-users': 'التحكم بالمستخدمين',
  'manage-settings': 'التحكم بالإعدادات',
  'manage-statistics': 'مشاهدة الإحصائيات',
  'manage-activities': 'مشاهدة سجل التغييرات بالشجرة',
  family_name: 'اسم العائلة',
  created: 'إنشاء',
  updated: 'تعديل',
  deleted: 'حذف',
  restored: 'استعادة',
  name: 'الاسم',
  life_status: 'حي أو متوفى',
  birth_date: 'تاريخ الولادة',
  death_date: 'تاريخ الوفاة',
  mobile: 'رقم الجوال',
  gender: 'الجنس',
  about: 'النبذة',
  full_name: 'الاسم الكامل',
  nickname: 'اللقب',
  marriage: 'زواج',
  divorce: 'طلاق',
  widow: 'أرمل',
};
