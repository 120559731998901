export const theme = {
  TInput: {
    fixedClasses:
      'block w-full border-gray-300 px-3 py-2 mt-2 transition duration-200 ease-in-out border rounded shadow-sm focus:ring focus:ring-green-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
    classes: 'text-black placeholder-gray-400 bg-white focus:border-green-500 ',
    variants: {
      danger: {
        classes: 'border-red-300 bg-red-50 placeholder-red-200 text-red-900',
      },
      success: {
        classes: 'border-green-300 bg-green-50 placeholder-gray-400 text-green-900',
      },
    },
  },
  TTextarea: {
    fixedClasses:
      'block w-full border-gray-300 px-3 py-2 my-2 transition duration-200 ease-in-out border rounded shadow-sm focus:ring focus:ring-green-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
    classes: 'text-black placeholder-gray-400 bg-white border-gray-300 focus:border-green-500 ',
    variants: {
      danger: {
        classes: 'border-red-300 bg-red-50 placeholder-red-200 text-red-900',
      },
      success: {
        classes: 'border-green-300 bg-green-50 placeholder-gray-400 text-green-900',
      },
    },
  },
  TSelect: {
    fixedClasses:
      'block w-full pl-3 pr-10 py-2 transition duration-200 ease-in-out border rounded shadow-sm focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
    classes: 'text-black placeholder-gray-400 bg-white border-gray-300 focus:border-green-500 ',
    variants: {
      danger: {
        classes: 'border-red-300 bg-red-50 placeholder-red-200 text-red-900',
      },
      success: {
        classes: 'border-green-300 bg-green-50 placeholder-gray-400 text-green-900',
      },
    },
  },
  TRadio: {
    fixedClasses:
      'transition duration-200 ease-in-out shadow-sm focus:border-green-500 focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed',
    classes: 'text-green-500 border-gray-300',
    variants: {
      error: {
        classes: 'text-red-500 border-red-300',
      },
      success: {
        classes: 'text-green-500 border-green-300',
      },
    },
  },
  TCheckbox: {
    fixedClasses:
      'transition duration-200 ease-in-out rounded shadow-sm focus:border-green-500 focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed',
    classes: 'text-green-500 border-gray-300 ',
    variants: {
      error: {
        classes: 'text-red-500 border-red-300',
      },
      success: {
        classes: 'text-green-500 border-green-300',
      },
    },
  },
  TButton: {
    fixedClasses:
      'font-bold px-4 py-2 m-1 transition duration-200 ease-in-out focus:border-green-500 focus:ring focus:ring-green-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
    classes: 'text-white bg-green-500 border border-transparent shadow-sm rounded hover:bg-green-600',
    variants: {
      secondary: {
        classes: 'text-green-500 hover:text-green-700 bg-white border border-transparent shadow rounded hover:bg-white',
      },
      warning: {
        classes:
          'text-white bg-yellow-500 border border-transparent shadow-sm rounded hover:bg-yellow-600 focus:ring focus:ring-yellow-200 focus:border-yellow-500',
      },
      error: {
        classes: 'text-white bg-red-500 border border-transparent rounded shadow-sm hover:bg-red-600',
      },
      success: {
        classes: 'text-white bg-green-500 border border-transparent rounded shadow-sm hover:bg-green-600',
      },
    },
  },
  TInputGroup: {
    fixedClasses: {
      wrapper: '',
      label: 'block',
      body: '',
      feedback: ' text-sm text-sm',
      description: 'text-gray-400 text-sm',
    },
    classes: {
      wrapper: '',
      label: '',
      body: '',
      feedback: 'text-gray-400',
      description: 'text-gray-400',
    },
    variants: {
      danger: {
        label: 'text-red-500',
        feedback: 'text-red-500',
      },
      success: {
        label: 'text-green-500',
        feedback: 'text-green-500',
      },
    },
  },
  TRichSelect: {
    fixedClasses: {
      wrapper: 'relative',
      buttonWrapper: 'inline-block relative w-full',
      selectButton:
        'w-full flex  justify-between items-center px-3 py-2 text-black transition duration-200 ease-in-out border rounded shadow-sm focus:border-green-500 focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
      selectButtonLabel: 'block truncate',
      selectButtonPlaceholder: 'block truncate',
      selectButtonIcon: 'fill-current flex-shrink-0 mx-1 h-4 w-4',
      selectButtonClearButton:
        'rounded flex flex-shrink-0 items-center justify-center absolute left-0 top-0 m-2 h-6 w-6 transition duration-200 ease-in-out',
      selectButtonClearIcon: 'fill-current h-3 w-3',
      dropdown: 'absolute w-full z-10 -mt-1 absolute border-b border-l border-r rounded-b shadow-sm z-10',
      dropdownFeedback: '',
      loadingMoreResults: '',
      optionsList: 'overflow-auto',
      searchWrapper: 'inline-block w-full focus:border-green-500',
      searchBox: 'inline-block w-full',
      optgroup: '',
      option: 'cursor-pointer',
      disabledOption: 'opacity-50 cursor-not-allowed',
      highlightedOption: 'cursor-pointer',
      selectedOption: 'cursor-pointer',
      selectedHighlightedOption: 'cursor-pointer',
      optionContent: '',
      optionLabel: 'truncate block',
      selectedIcon: 'fill-current h-4 w-4',
      enterClass: '',
      enterActiveClass: '',
      enterToClass: '',
      leaveClass: '',
      leaveActiveClass: '',
      leaveToClass: '',
    },
    classes: {
      wrapper: '',
      buttonWrapper: '',
      selectButton: 'bg-white border-gray-300',
      selectButtonLabel: '',
      selectButtonPlaceholder: 'text-gray-400',
      selectButtonIcon: 'text-gray-600',
      selectButtonClearButton: 'hover:bg-green-100 text-gray-600',
      selectButtonClearIcon: '',
      dropdown: 'bg-white border-gray-300',
      dropdownFeedback: 'pb-2 px-3 text-gray-400 text-sm',
      loadingMoreResults: 'pb-2 px-3 text-gray-400 text-sm',
      optionsList: '',
      searchWrapper: 'p-2 placeholder-gray-400',
      searchBox: 'px-3 py-2 bg-gray-50 text-sm rounded border focus:outline-none focus:shadow-outline border-gray-300',
      optgroup: 'text-gray-400 uppercase text-xs py-1 px-2 font-semibold',
      option: 'w-full',
      disabledOption: '',
      highlightedOption: 'bg-green-100',
      selectedOption: 'font-semibold bg-gray-100 bg-green-500 font-semibold text-white',
      selectedHighlightedOption: 'font-semibold bg-gray-100 bg-green-600 font-semibold text-white',
      optionContent: 'flex justify-between items-center px-3 py-2',
      optionLabel: '',
      selectedIcon: '',
      enterClass: '',
      enterActiveClass: 'opacity-0 transition ease-out duration-200',
      enterToClass: 'opacity-100',
      leaveClass: 'transition ease-in opacity-100',
      leaveActiveClass: '',
      leaveToClass: 'opacity-0 duration-250',
    },
    variants: {
      danger: {
        selectButton: 'border-red-300 bg-red-50 text-red-900',
        selectButtonPlaceholder: 'text-red-200',
        selectButtonIcon: 'text-red-500',
        selectButtonClearButton: 'hover:bg-red-200 text-red-500',
        dropdown: 'bg-red-50 border-red-300',
      },
      success: {
        selectButton: 'border-green-300 bg-green-50 text-green-900',
        selectButtonIcon: 'text-green-500',
        selectButtonClearButton: 'hover:bg-green-200 text-green-500',
        dropdown: 'bg-green-50 border-green-300',
      },
    },
  },
  TTag: {
    fixedClasses: '',
    classes: '',
    variants: {
      title: 'text-2xl leading-8 font-extrabold text-gray-900 tracking-tight',
      subtitle: 'text-lg leading-6 font-medium text-gray-900',
      error: 'text-red-500',
      badge: 'inline-flex items-center px-3 rounded-full text-xs font-medium leading-4 bg-gray-100 text-gray-800',
      avatar:
        'inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500 overflow-hidden leading-none text-center',
    },
  },
  TAlert: {
    fixedClasses: {
      wrapper: 'relative flex items-center p-4 border-l-4  rounded shadow-sm',
      body: 'flex-grow',
      close:
        'absolute relative flex items-center justify-center mx-4 flex-shrink-0 w-6 h-6 transition duration-200 ease-in-out rounded  focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50',
      closeIcon: 'fill-current h-4 w-4',
    },
    classes: {
      wrapper: 'bg-green-50 border-green-500',
      body: 'text-green-700',
      close: 'text-green-500 hover:bg-green-200',
    },
    variants: {
      danger: {
        wrapper: 'bg-red-50 border-red-500',
        body: 'text-red-700',
        close: 'text-red-500 hover:bg-red-200',
      },
      success: {
        wrapper: 'bg-green-50 border-green-500',
        body: 'text-green-700',
        close: 'text-green-500 hover:bg-green-200',
      },
    },
  },
  TCard: {
    fixedClasses: {
      wrapper: 'border rounded shadow-sm ',
      body: 'p-3',
      header: 'border-b p-3 rounded-t',
      footer: 'border-t p-3 rounded-b',
    },
    classes: {
      wrapper: 'bg-white border-gray-100',
      body: '',
      header: 'border-gray-100',
      footer: 'border-gray-100',
    },
    variants: {
      danger: {
        wrapper: 'bg-red-50 text-red-700 border-red-200',
        header: 'border-red-200 text-red-700',
        footer: 'border-red-200 text-red-700',
      },
    },
  },
  TModal: {
    fixedClasses: {
      overlay: 'z-40  overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-opacity-50',
      wrapper: 'relative mx-auto z-50 max-w-lg px-3 py-12',
      modal: 'overflow-visible relative  rounded',
      body: '',
      header: 'border-b p-3 rounded-t',
      footer: ' p-3 rounded-b',
      close:
        'flex items-center justify-center rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-200 ease-in-out focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50',
    },
    classes: {
      overlay: 'bg-black',
      wrapper: '',
      modal: 'bg-white shadow',
      body: '',
      header: 'border-gray-100',
      footer: 'bg-gray-100',
      close: 'bg-gray-100 text-gray-600 hover:bg-gray-200 z-10',
      closeIcon: 'fill-current h-4 w-4',
      overlayEnterClass: '',
      overlayEnterActiveClass: 'opacity-0 transition ease-out duration-200',
      overlayEnterToClass: 'opacity-100',
      overlayLeaveClass: 'transition ease-in opacity-100',
      overlayLeaveActiveClass: '',
      overlayLeaveToClass: 'opacity-0 duration-250',
      enterClass: '',
      enterActiveClass: 'transition ease-out duration-250 transform opacity-0 scale-95',
      enterToClass: 'transform duration-250 opacity-100 scale-100',
      leaveClass: 'transition ease-in transform duration-250 opacity-100 scale-100',
      leaveActiveClass: '',
      leaveToClass: 'transform duration-250 opacity-0 scale-95 duration-250',
    },
    variants: {
      danger: {
        overlay: 'bg-red-100',
        header: 'border-red-50 text-red-700',
        close: 'bg-red-50 text-red-700 hover:bg-red-200 border-red-100 border',
        modal: 'bg-white border border-red-100 shadow-lg',
        footer: 'bg-red-50',
      },
    },
  },
  TDropdown: {
    fixedClasses: {
      button:
        'flex items-center text-white block px-2 py-2 transition duration-200 ease-in-out border border-transparent rounded shadow-sm focus:border-green-500 focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
      wrapper: 'inline-flex flex-col',
      dropdownWrapper: 'relative z-10',
      dropdown: 'z-50 origin-top-left absolute left-0 w-10 rounded shadow mt-1',
      enterClass: '',
      enterActiveClass: 'transition ease-out duration-250 transform opacity-0 scale-95',
      enterToClass: 'transform duration-250 opacity-100 scale-100',
      leaveClass: 'transition ease-in transform duration-250 opacity-100 scale-100',
      leaveActiveClass: '',
      leaveToClass: 'transform duration-250 opacity-0 scale-95 duration-250',
    },
    classes: {
      button: 'bg-green-500 hover:bg-green-600',
      dropdown: 'bg-white',
    },
    variants: {
      danger: {
        button: 'bg-red-500 hover:bg-red-600',
        dropdown: 'bg-red-50',
      },
    },
  },
};
