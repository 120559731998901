import axios from 'axios';
import { toast } from '@/utils/helpers.ts';

export const fetcher = async ({ url, data, method = 'get', form, headers }) => {
  try {
    const response = await axios({
      method: method,
      url: url,
      [method === 'get' ? 'params' : 'data']: data,
      headers,
    });
    if (response.data.success) {
      toast(response.data.success);
    }

    return response.data.data;
  } catch (e) {
    console.log('fetcher error', { e });
    if (!e.response && !form) {
      toast('حدث خطأ ما', 'error');
      throw e;
    }

    const errors = e.response?.data.errors;

    if (!errors) {
      throw e;
    }

    if (Array.isArray(errors)) {
      if (form) {
        form.errors = errors.flat();
        throw e;
      }

      toast(errors[0], 'error');

      throw e;
    }

    for (let [key, value] of Object.entries(errors)) {
      errors[key] = value[0];
    }

    form.errors = errors;

    throw e;
  }
};
